import * as SentrySvelte from '@sentry/svelte'
import { PUBLIC_LOGROCKET_APP_ID } from '$env/static/public'
import { basicSentryConfig } from '$lib/utilities/errorTrackingTools.js'
import LogRocket from 'logrocket'
import { dev, browser } from '$app/environment'
import { country, currency, DTBalanceIsGood } from './stores/index.js'
import axios from 'axios'
import handleAxiosError from '$lib/utilities/handleAxiosError.js'
import Cookies from 'js-cookie'
import { analytics } from '$lib/utilities/AnalyticsTools'
import { getCurrencyForCountry } from '$lib/utilities/getCurrencyForCountry'

// Add catch-all error-handler to axios
axios.interceptors.response.use(
    response => response,
    error => handleAxiosError(error),
)

if (!dev) {
    // Initialize our error trackers
    if (browser) {
        window.lrPromise.then(() => LogRocket.init(PUBLIC_LOGROCKET_APP_ID))
    }

    SentrySvelte.init({
        ...basicSentryConfig,
        integrations: [new SentrySvelte.BrowserTracing()],
    })

    SentrySvelte.setTag('svelteKit', 'browser')
}

/**
 * Add subscriber for country events, fetch products for country and set currency by country
 */
country.subscribe(country => {
    currency.set(getCurrencyForCountry(country))
})

if (Cookies.get('rememberCustomerNumber')) {
    analytics.identify(Cookies.get('rememberCustomerNumber'))
}

// Check DTone balance status and set a store value accordingly
axios.get(`/api/balances/dtone`, { useGlobalErrorHandler: false }).then(({ data }) => {
    DTBalanceIsGood.set(data)
})

// This will catch errors in load functions from +page.ts files
export const handleError = ({ error, event }) => {
    console.error(error)

    SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } })

    return {
        message: error.message,
    }
}
